nav.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: rgba(255, 255, 255, 0.99);
  -webkit-backdrop-filter: blur(10px);
  z-index: 60;

  .navbar-item {
    font-weight: 500 !important;
    a {
      // color: rgb(74,74, 74);
    }
    a:hover {
      color: rgb(74, 74, 74);
    }
  }
}
