@import "bulma/utilities/_all";

nav.navbar{
  @include until($tablet) {
    .w-50 {
      width: 100% !important;
    }   
  }
}

@include until($tablet){
 .w-75{
    width: 100% !important;     
  }
}