.has-shadow{
   box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.has-blue-shadow{
   box-shadow: 0 1px 2px rgba(41,52,204,.1) , 0 2px 4px rgba(41,52,204,.2);
}

.has-grey-shadow{
   box-shadow: 0 1px 2px rgba(74,74,74,.1) , 0 2px 4px rgba(74,74,74,.2);
}