$list-background-color: $white !default
$list-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default
$list-radius: $radius !default

$list-item-border: 1px solid $border !default
$list-item-color: $text !default
$list-item-active-background-color: $link !default
$list-item-active-color: $link-invert !default
$list-item-hover-background-color: $background !default

.list
  @extend %block
  background-color: $list-background-color
  border-radius: $list-radius
  box-shadow: $list-shadow
  // &.is-hoverable > .list-item:hover:not(.is-active)
  //   background-color: $list-item-hover-background-color
  //   cursor: pointer

.list-item
  display: block
  padding: 0.5em 1em
  &:not(a)
    color: $list-item-color
  &:first-child
    border-top-left-radius: $list-radius
    border-top-right-radius: $list-radius
  &:last-child
    border-top-left-radius: $list-radius
    border-top-right-radius: $list-radius
  &:not(:last-child)
    border-bottom: $list-item-border
  &.is-active
    background-color: $list-item-active-background-color
    color: $list-item-active-color

a.list-item
  background-color: $list-item-hover-background-color
  cursor: pointer